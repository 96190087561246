import React, { Suspense } from 'react';
import { Content, ContentType } from '../components/content';
import { Spinner } from '../components/spinner';
import { UserJwt } from '../blocks/userJwt';
import { Claims } from '../blocks/claims';
import { Links } from '../blocks/links';
import { GraphJWT } from '../blocks/graphJwt';

export const Viewer: React.FC = () => (
  <div>
    <Content className="my-8" type={ContentType.text}>
      <Suspense fallback={<Spinner />}>
        <div className="grid xl:grid-cols-2 lg:grid-cols-1 gap-6">
          <UserJwt />
          <Claims />
          <GraphJWT />
          <Links />
        </div>
      </Suspense>
    </Content>
  </div>
);
