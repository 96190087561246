import React from 'react';
import { useAtom } from 'jotai';
import { userData } from '../state/userData';
import { JWT } from './jwt';

export const GraphJWT: React.FC = () => {
  const [data] = useAtom(userData);

  return (<JWT name="MS Graph JWT" jwt={data.accessToken} />);
};
