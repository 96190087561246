import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { LoginButton } from '../components/loginButton';

export const Header: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  const headerText = isAuthenticated
    // @ts-ignore
    ? `Hello ${accounts?.[0]?.name ?? 'there'}`
    : 'JWT Viewer';

  return (
    <div className="relative bg-white">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <div className="text-4xl">{headerText}</div>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <LoginButton />
          </div>
        </div>
      </div>
    </div>
  );
};
