import React from 'react';

const ListItem: React.FC<ListItemProps> = ({ href, linkText, description }) => (
  <li>
    <a
      className="link"
      href={href}
    >
      {linkText}
    </a>
    {
      description
        ? ` - ${description}`
        : ''
    }
  </li>
);

export const Links: React.FC = () => (
  <div>
    <h2 className="text-2xl pb-8">Links</h2>
    <ul className="list-disc">
      <ListItem
        href="https://jwt.io"
        linkText="jwt.io"
        description="Information about Libraries, and Utilities for, JWTs"
      />
      <ListItem
        href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredApps"
        linkText="Azure Application Portal"
        description="Register and Manage Apps"
      />
      <ListItem
        href="https://github.com/ILC-Technology/ApiGatewayAuthorizer"
        linkText="API Gateway Authorizer"
        description="Ready to go request auth validation for lambda's"
      />
      <ListItem
        href="https://github.com/ILC-Technology/jwt-viewer"
        linkText="Source Code for this page"
        description="Feel free to submit improvements!"
      />
    </ul>
  </div>
);

interface ListItemProps {
  href: string;
  linkText: string;
  description?: string;
}
