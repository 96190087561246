import React from 'react';
import { useAtom } from 'jotai';
import { userData } from '../state/userData';
import { JWT } from './jwt';

export const UserJwt: React.FC = () => {
  const [data] = useAtom(userData);

  return (<JWT name="User JWT" jwt={data.idToken} />);
};
