import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

export const LoginButton: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const handleLogin = () => instance.loginPopup(loginRequest).catch((e) => { console.log(e); });
  const handleLogout = () => instance.logoutPopup();

  const buttonAction = isAuthenticated ? handleLogout : handleLogin;
  const buttonText = isAuthenticated ? 'Logout' : 'Sign in';

  return (
    <button type="button" onClick={() => buttonAction()} className="button">
      {buttonText}
    </button>
  );
};
