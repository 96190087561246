import { LogLevel } from '@azure/msal-browser';
import { Configuration } from '@azure/msal-browser/dist/config/Configuration';
import { PopupRequest } from '@azure/msal-browser/dist/request/PopupRequest';

const redirectUri = window.location.hostname === 'localhost'
  ? 'http://localhost:3000/'
  : 'https://jwt.eflangtech.com/';

export const msalConfig: Configuration = {
  auth: {
    clientId: '6b669278-d040-469f-8a23-2d7fdbf38209',
    authority: 'https://login.microsoftonline.com/f0d1c6fd-dff0-486a-8e91-cfefefc7d98d',
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(level, message);
        }
      },
    },
  },
};

export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};
