import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Header } from './blocks/header';
import { Viewer } from './pages/viewer';
import { Login } from './pages/login';

export const App: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Header />
      { isAuthenticated ? <Viewer /> : <Login /> }
    </>
  );
};
