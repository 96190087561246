import React from 'react';
import useCopy from '@react-hook/copy';

export const JWT: React.FC<{ name: string; jwt: string; }> = ({ name, jwt }) => {
  const { copied, copy } = useCopy(jwt);

  return (
    <div>
      <div>
        <h2 className="text-2xl pb-8">{name}</h2>
      </div>
      <p
        style={{
          wordWrap: 'break-word',
          fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
        }}
      >
        {jwt}
      </p>
      <div className="pt-4">
        {
          copied
            ? <p className="text-1xl font-bold text-green-400">Copied!</p>
            : <button type="button" className="text-1xl font-bold text-blue-400" onClick={() => copy()}>Copy</button>
        }
      </div>
    </div>
  );
};
