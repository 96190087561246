import { atom } from 'jotai';
import { useMsal } from '@azure/msal-react';
import { AuthenticationResult } from '@azure/msal-common';
import { loginRequest } from '../authConfig';

export const userData = atom<Promise<AuthenticationResult>>(async () => {
  const { instance, accounts } = useMsal();

  return instance.acquireTokenSilent({
    ...loginRequest,
    // @ts-ignore
    account: accounts[0],
  });
});
