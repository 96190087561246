import React from 'react';
import { useAtom } from 'jotai';
import { userData } from '../state/userData';

export const Claims: React.FC = () => {
  const [data] = useAtom(userData);

  return (
    <div>
      <h2 className="text-2xl pb-8">Claims</h2>
      <pre className="overflow-x-scroll">{JSON.stringify(data.idTokenClaims, null, 2)}</pre>
    </div>
  );
};
